<template>
  <div class="relative mx-auto mb-16 flex w-full max-w-screen-sm flex-col px-6">
    <portal to="topbar-left">
      <router-link
        :to="{ name: 'info-index' }"
        class="relative font-bold"
        key="info-articles-back"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="h-6 w-6">
          <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
            <path d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z" />
          </g>
        </svg>
      </router-link>
    </portal>

    <h1 class="mb-4 text-3xl font-bold">
      {{ $t("navigation.chemsex_stories") }}
    </h1>

    <t-input-group class="mb-8 w-full">
      <t-input :placeholder="$t('pages.search_placeholder')" type="text" v-model="searchText" @keydown="searchStories" />
    </t-input-group>

    <loading v-if="loading"></loading>

    <div v-else class="grid grid-cols-1 gap-2">
      <article-item v-for="chemsexStory in chemsexStories" :article="chemsexStory" :key="chemsexStory.id" class="w-full" />
    </div>
  </div>
</template>

<script>
import ArticleItem from "components/Articles/ArticleItem";
import { mapGetters } from "vuex";
import { debounce as _debounce } from "lodash";

export default {
  name: "Chemsex",

  components: {
    ArticleItem,
  },

  async mounted() {
    this.loading = true;
    await this.initArticles();
    this.loading = false;
  },

  data() {
    return {
      loading: false,
      searchText: "",
    };
  },

  methods: {
    async initArticles() {
      await this.$store.dispatch("getChemsexStories", { searchText: this.searchText, language: this.$i18n.locale });
    },

    searchStories: _debounce(function () {
      this.initArticles();
    }, 500),
  },

  computed: {
    ...mapGetters(["chemsexStories", "isCheckedIn"]),
  },
};
</script>

<style scoped></style>
